import { ref } from "vue";
import { pageappnotice, userReadNotice } from "@/api/appNotice"; // 导入接口

import { useRouter } from "vue-router";
import { hideLoading, showLoading } from "@/utils/utils";
export default {
  name: "tutoriallist",

  setup() {
    const router = useRouter();
    const searchValue = ref(""); // 搜索关键词

    const loading = ref(false); // 控制加载状态

    const finished = ref(false); // 控制是否已加载完所有数据

    const items = ref([]); // 存储教程数据

    const currentPage = ref(1); // 当前加载的页码

    const pageSize = 10; // 每次加载的条目数

    const total = ref(0); // 总数据条目数
    // 获取用户信息

    const userInfoString = localStorage.getItem("userInfo");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

    const onClickLeft = () => history.back();

    const toDetail = async item => {
      if (!userInfo || !userInfo.loginName) {
        console.error("LoginName is missing from localStorage.");
        return;
      }

      try {
        // 调用用户已读消息接口
        const res = await userReadNotice({
          loginName: userInfo.loginName,
          noticeId: item.id // 当前教程的 id

        });

        if (res.code === 1) {
          // 接口调用成功后跳转到详情页面
          router.push({
            path: "/home/newTutoria/details",
            query: {
              data: JSON.stringify(item)
            }
          });
        } else {
          console.error("Failed to mark notice as read", res.msg);
        }
      } catch (error) {
        console.error("Error marking notice as read", error);
      }
    }; // 加载教程数据


    const loadTutorials = async (reset = false) => {
      if (reset) {
        // 如果是搜索或重置，则重置列表和分页状态
        items.value = [];
        currentPage.value = 1;
        finished.value = false;
      }

      showLoading("Loading tutorials..."); // 显示加载状态

      try {
        // 调用接口获取数据，传递 type=1 代表教程类型
        const response = await pageappnotice({
          type: "1",
          current: currentPage.value,
          size: pageSize
        });

        if (response.code === 1) {
          const newItems = response.data.records;
          total.value = response.data.total; // 获取总数据条目数
          // 将新获取的数据添加到列表中

          items.value.push(...newItems); // 如果加载的数据已经达到或超过总条目数，标记为已加载完成

          if (items.value.length >= total.value) {
            finished.value = true;
          } else {
            // 增加页码，准备下次加载
            currentPage.value++;
          }
        } else {
          console.error("获取教程数据失败", response.msg);
        }
      } catch (error) {
        console.error("请求失败", error);
      }

      hideLoading(); // 隐藏加载状态
    }; // 加载更多数据


    const onLoad = () => {
      loadTutorials();
    }; // 搜索功能


    const onSearch = val => {
      console.log(`搜索内容: ${val}`);
      searchValue.value = val;
      loadTutorials(true); // 重置列表并重新加载
    };

    return {
      searchValue,
      onClickLeft,
      onSearch,
      loading,
      finished,
      items,
      onLoad,
      toDetail
    };
  }

};